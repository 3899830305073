import React, { useEffect, useRef, useState } from 'react';

const Video = ({ videoSrcURL, videoTitle, ...props }) => {

  const iframe = useRef()
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => setLoaded(true)
    let frame = iframe.current
    frame.addEventListener('load', handleLoad, true)
    let src = frame.getAttribute('data-src')
    if (src) frame.setAttribute('src', src)
    return () => {
      frame.removeEventListener('load', handleLoad)
    }
  }, []) 

  return (
    <div className="element-video mb-24 bg-gray-200">
      {!loaded && <p className='absolute top-4 left-4 font-mono'>Video loading...</p>}
      <iframe
        src=""
        data-src={videoSrcURL}
        title={videoTitle}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        loading='lazy'
        allowFullScreen
        ref={iframe}
      />
    </div>
  )
}

export default Video