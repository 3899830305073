import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ArticleTemplate from "../../components//articleTemplate"

export default function Template({
  data, 
}) {
  const post = data.markdownRemark
  const { frontmatter } = post
  const {siteUrl, twitter, author} = data.site.siteMetadata
  let description = frontmatter.description.replace(/(<([^>]+)>)/gi, "")
  let image = frontmatter.hero_image
  if (image) image = image.replace('http://', 'https://')
  let date = new Date(frontmatter.date)
  return (
    <Layout>
      <Seo 
            title={frontmatter.title} 
            description={description} 
            image = {image}
            keywords = {frontmatter.keywords}
            type = 'BlogPosting'
      >
        <script type='application/ld+json'>
              {`{
                  "@context": "https://schema.org",
                  "@type": "BlogPosting",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "${siteUrl}/archive/${frontmatter.slug}"
                  },
                  "datePublished": "${date}",
                  "headline": "${frontmatter.title}",
                  "image": "${frontmatter.hero_image}",
                  "url": "${siteUrl}/archive/${frontmatter.slug}",
                  "description": "${frontmatter.description}",
                  "editor": "${author}",
                  "inLanguage": "en-GB",
                  "author": {
                    "@type": "Person",
                    "name": "${frontmatter.author}"
                }
              }`}
        </script>
      </Seo>
        <ArticleTemplate post={post} siteUrl={siteUrl} twitter={twitter}></ArticleTemplate>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        twitter
        siteUrl
        author
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      timeToRead
      id
      frontmatter {
        date(formatString: "MMM DD YYYY")
        slug
        title
        description
        hero_image_alt
        hero_image
        hero_image_credit
        keywords
        publication
        author
        authorurl
        authorbio
        authorimg
        authortwitter
        webUrl
        starRating
      }
    }
  }
`
