import React from 'react';
import PropTypes from "prop-types"

import TwitterEmbed from './TwitterEmbed'

const Author = ({author, authortwitter, authorurl, authorbio, authorimg}) => {

    let twitterlimit = 3

    return (
        <aside id='author' className='py-4 px-2 bg-gray-200'>
            <div className='md:max-w-3xl mx-auto'>
                <h2 className='text-2xl mb-4'>
                   <small>Author</small>
                   {authorurl ? <a href={authorurl} target='_blank' rel="noreferrer">{author}</a> : `${author}`}
                </h2>
                {authorbio && <div className='mb-6' dangerouslySetInnerHTML={{ __html: authorbio }} />}
                {authortwitter && <TwitterEmbed handle={authortwitter} limit={twitterlimit}></TwitterEmbed>}
            </div>         
        </aside>
    )

}

Author.defaultProps = {
    authortwitter: null, 
    authorurl: null,
    authorbio: null, 
    authorimg: null
}

Author.propTypes = {
    author: PropTypes.string, 
    authortwitter: PropTypes.string, 
    authorurl: PropTypes.string,
    authorbio: PropTypes.string, 
    authorimg: PropTypes.string
}

export default Author