import * as React from 'react'
import { Link } from 'gatsby'

import { useStaticQuery, graphql } from "gatsby"

const Navigation = ( { id } ) => {

    const { allMarkdownRemark } = useStaticQuery(
        graphql`
        query {
            allMarkdownRemark (sort: {fields: frontmatter___date, order: DESC}) {
                edges {
                  next {
                    frontmatter {
                      title
                      slug
                    }    
                  }
                  previous {
                    frontmatter {
                      title
                      slug
                    }
                  }
                  node {
                    id
                  }
                }
              }
            }
        `
      )
    
    const {edges} = allMarkdownRemark

    let
    next,
    previous

    for (let i = 0; i < edges.length; i++) {
        if (edges[i].node.id === id) { 
            previous = edges[i].next
            next = edges[i].previous
            break; 
        }
    }

    return (
        <nav className='text-lg flex flex-col md:flex-row justify-between text-3xl px-4'>
            <div className='text-center md:text-left mb-8'>{
              next && <>
              <p className='font-mono uppercase text-base'>Next</p>
              <Link to={`/archive/${next.frontmatter.slug}/`}>
                {next.frontmatter.title.split(' review – ')[0]}
              </Link>
              </>}
            </div> 
            <div className='text-center md:text-right'>{
              previous && <>
              <p className='font-mono uppercase text-base'>Previous</p>
              <Link to={`/archive/${previous.frontmatter.slug}/`}>
                {previous.frontmatter.title.split(' review – ')[0]}
              </Link>
              </>
              }
            </div> 
        </nav> 
    )

}

export default Navigation