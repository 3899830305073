import React, { useState, useEffect, useRef } from 'react'
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Navigation from "./navigation"
import Tags from './tags'
import Video from "./video"
import Favebutton from "./faveButton"
import ShareButtonsWrapper from "./shareButtonsWrapper"
import TwitterEmbed from './TwitterEmbed'
import Rating from './rating'
import Author from './Author'

import processTags from '../services/processTags'

import {
    pagetitle,
  } from './layout.module.css'

const ArticleTemplate = ( { post, siteUrl, twitter } ) => {

    const [youtubelinks, setYoutubelinks] = useState([]);
    const [twitterlinks, setTwitterlinks] = useState([]);

    const { frontmatter, html, timeToRead, id } = post
    const body = useRef()
    let description = frontmatter.description.replace(/(<([^>]+)>)/gi, "")
    let image = frontmatter.hero_image
    let keywords = processTags.process(frontmatter.keywords)
    let {starRating, authortwitter, authorurl, authorbio, authorimg} = frontmatter

    const shareEvent = (category, url) => {
        trackCustomEvent({
          category: category,
          action: "Click",
          label: url,
        })
    }

    if (image) image = image.replace('http://', 'https://')

    useEffect(() => {
        let arr = []
        let tweets = []
        const modifyYouTubeLink = (src) => {
            let embed = 'https://www.youtube.com/embed/'
            if (src.indexOf('/embed/') !== -1) {
                src = src.split('/embed/')[1].split('?')[0]
            } else {
                src = src.split('v=')[1].split('?')[0]
            }
            return `${embed}${src}`
        }
        const modifyLinks = (doc) => {
            let links = doc.getElementsByTagName('a')
            for (let i = 0; i < links.length; i++){
                let el = links[i]
                let url = el.getAttribute('href')
                if (url) {
                    if (url.indexOf('http://') !== -1) el.setAttribute('href', url.replace('http://','https://'))
                    if (url.indexOf('youtube.com') !== -1) arr.push(modifyYouTubeLink(url))
                    if (url.indexOf('twitter.com') !== -1) tweets.push(url.split('.com/')[1].split('?')[0])
                    el.setAttribute("target", "_blank")
                    el.setAttribute("rel", "noopener")
                    el.onclick = function () {
                        shareEvent('External Links', url)
                    }
                }
            }
        }
        let arr2 = []
        const extractVideos = (doc) => {
            let iframes = doc.getElementsByTagName('iframe')
            for (let i = 0; i < iframes.length; i++){
                let el = iframes[i]
                let src = el.getAttribute('src')
                if (src && src.indexOf('youtube') !== -1) arr2.push(modifyYouTubeLink(src))
            }
        }
        modifyLinks(body.current)
        extractVideos(body.current)
        let videos = arr.concat(arr2.filter((item) => arr.indexOf(item) < 0))
        setYoutubelinks(videos)
        setTwitterlinks(tweets)
    }, []) 

    return (
        <>
        <article className="blog-post-container mx-auto max-w-full py-8">
            <div className="blog-post mb-4 prose-lg lg:prose-xl">
                <header className="not-prose md:max-w-5xl mx-auto px-4">
                    <h1 className={pagetitle}>{frontmatter.title} {starRating && <small className='pt-4'><Rating rating={starRating}></Rating></small>}</h1>
                </header>
                
                <div className='flex justify-between md:max-w-5xl mx-auto px-4 xl:px-0'>
                    <div>
                        <p className='text-base md:text-lg font-mono'>{frontmatter.date} - {timeToRead} min read</p>
                    </div>
                    <Favebutton post={post} size={'48px'}></Favebutton>
                </div>
                <div className='md:max-w-5xl mx-auto px-2 xl:px-0'>
                    <div className='hidden md:block'>
                        <Tags tags={keywords} nowrap={true}></Tags>
                    </div>
                    <p className="text-xl md:text-2xl text-center">{description}</p>
                </div>
                
                {image && 
                    <figure className='max-w-full md:max-w-5xl mx-auto mb-4 text-center'>
                        <img src={image} alt={frontmatter.hero_image_alt || frontmatter.title} className='w-full aspect-[5/3]' />
                        {frontmatter.hero_image_alt && 
                            <figcaption>
                                {frontmatter.hero_image_alt} 
                                <small>{frontmatter.hero_image_credit}</small>
                            </figcaption>
                            }
                    </figure>
                }
                <div
                id='body'
                ref={body}
                className="blog-post-content px-2 md:px-4 lg:px-0 md:max-w-3xl mx-auto"
                dangerouslySetInnerHTML={{ __html: html }}
                />
                <div className='flex flex-col md:flex-row justify-between px-2 lg:px-0 md:max-w-3xl mx-auto'>
                    <p className='text-base font-mono'>Original: <a href={frontmatter.webUrl} target='_blank' rel="noreferrer">{frontmatter.publication}</a></p>
                    {frontmatter.author && <p className='text-base font-mono'>Author: <a href='#author'>{frontmatter.author}</a></p>}
                </div>
            </div>
            {
              youtubelinks && <div className='md:max-w-3xl mx-auto hidden md:block'>
                {youtubelinks.map((video, index) => (
                <Video
                    videoSrcURL={video}
                    videoTitle={frontmatter.title}
                    key={index}
                />
              ))}
              </div>
            }
            {
               twitterlinks && twitterlinks.map((handle, index) => (
                <TwitterEmbed handle={handle} limit={3}  key={index}></TwitterEmbed>
              ))
              
            } 
            <ShareButtonsWrapper title={frontmatter.title} url={`${siteUrl}/archive/${frontmatter.slug}`} twitterHandle={twitter} tags={frontmatter.keywords} rating={starRating}></ShareButtonsWrapper>
            {frontmatter.author && <Author author={frontmatter.author} authortwitter={authortwitter} authorurl={authorurl} authorbio={authorbio} authorimg={authorimg}></Author>}
        </article>
        <div className="mx-auto max-w-full md:max-w-4xl mb-8">
            <Navigation id={id}></Navigation>
        </div>
        
        </>
    )

}

export default ArticleTemplate